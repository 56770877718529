<template>
  <div class="my-4 text-xs-center">
    <v-progress-circular indeterminate color="primary" :size="70" :width="7"/>
  </div>
</template>

<script>
  export default {
    name: "ProgressCircle"
  }
</script>